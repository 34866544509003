var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.content && Object.keys(_vm.content).length > 0)?_c('v-form',{ref:"form",attrs:{"id":"editPageForm","lazy-validation":""}},_vm._l((_vm.getContentData),function(items,index){return _c('v-row',{key:index,staticClass:"flex mt-0",attrs:{"dense":""}},_vm._l((items),function(item,i){return _c('pageElement',{key:i,ref:"pageElement",refInFor:true,staticClass:"align-stretch",attrs:{"item":item,"editMode":_vm.userIsAdministrator && _vm.editMode,"websiteId":_vm.page.WebsiteId.$oid,"pageId":_vm.page._id.$oid,"objectName":i,"colsWidth":item.colWidth},on:{"removeItem":function($event){return _vm.removeItem(i)},"moveUpNonRepPage":_vm.moveUpNonRepPage,"moveDownNonRepPage":_vm.moveDownNonRepPage}})}),1)}),1):_vm._e(),(_vm.userIsAdministrator && _vm.editMode)?[_c('br'),_c('v-card',{staticClass:"mb-4"},[_c('v-card-text',[_c('h3',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t("Add item")))]),_c('addRemovePageElement',{attrs:{"enableAdd":true},on:{"addItem":_vm.addObjectToPageContent}})],1)],1)]:_vm._e(),(
      _vm.$store.getters.hasUserRoleForPageItem({ role: 1 }) ||
      _vm.userIsAdministrator
    )?[_c('cancelConfirmButtons',{ref:"cancelConfirm",attrs:{"isNonRepeatingPage":true,"confirmFunction":_vm.saveContent,"loading":_vm.savingContent,"confirmLabel":_vm.$t('Save page')},on:{"goBack":function($event){return _vm.$emit('goBack')}}})]:_vm._e(),(_vm.success.length > 0)?_c('errorHandeling',{attrs:{"snackbarText":_vm.success,"buttons":[
      {
        isText: true,
        functionName: 'clearError',
        text: 'Close',
      },
    ],"snackbarColor":"success","snackbarTimout":"-1","snackbarIcon":"mdi-check"},on:{"clearError":function($event){_vm.success = ''}}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }